import React, { useState } from 'react';
import { Tabset, Tab, } from 'react-rainbow-components';
import { EndpointTryItOut } from './endpointTabs/EndpointTryItOut';
import { EndpointAxiosJavascript } from './endpointTabs/EndpointAxiosJavascript';


export const Endpoint = (params) => {
    const {httpMethod, path, spec, fullSpec} = params;

    const [selected, setSelected] = useState('try_it_out');

    let url = `${fullSpec.schemes[0]}://${(fullSpec.host + fullSpec.basePath + path).replaceAll("//", "/")}`;
    const getTabContent = () => {
        switch (selected) {
            case 'axios':
                return (
                    <EndpointAxiosJavascript
                        httpMethod={httpMethod}
                        path={path}
                        spec={spec}
                        fullSpec={fullSpec}
                        url={url}
                    />
                );
        }
        return (
        <EndpointTryItOut
            httpMethod={httpMethod}
            path={path}
            spec={spec}
            fullSpec={fullSpec}
            url={url}
        />
        );
    }

    const id = spec.operationId;

    return (
        <div id={id} className="rainbow-flex rainbow-flex_column rainbow_vertical-stretch">
        <Tabset
            id="tabset-1"
            onSelect={(e, v) => {setSelected(v)}}
            activeTabName={selected}
            className="rainbow-p-horizontal_x-large"
            variant='card'
        >
            <Tab
                label="Try it out!"
                name="try_it_out"
                id="primary"
                ariaControls="primaryTab"
            />
            <Tab
                label="Javascript (Axios)"
                name="axios"
                id="axios"
                ariaControls="recentsTab"
            />


            {/* 
            <Tab label="SHARED" name="shared" id="shared" ariaControls="sharedTab" />

            <Tab label="LOCKED" name="locked" id="locked" ariaControls="lockedTab" />

            <Tab label="FORUMS" name="forums" id="forums" ariaControls="forumsTab" /> */}
        </Tabset>
        <div>
            {getTabContent()}
        </div>
        
    </div>
    );
}