import { useState, useEffect } from "react";
import { get } from "../components/ApiCaller";
import { Endpoint } from "../components/Endpoint"
import { EndpointGroup } from "../components/EndpointGroup";


const docsCache = {};

/**
 * A Service represents a full swagger/openapi doc
 * @param { doc, docUrl } params 
 * doc - The swagger/open api docs
 * docUrl - If a doc is not present, it will attempt to load one from the url
 */
export const Service = (params) => {
    const { docUrl } = params;

    const [doc, setDoc] = useState(params.doc ? params.doc : {});

    const tagToEndpointMap = {};

    useEffect(() => {
        if (docUrl !== null && docUrl !== "") {
            let cachedDoc = docsCache[docUrl];
            if (cachedDoc) {
                setDoc(cachedDoc);
                return;
            }
            get(docUrl, {}, (json) => {
                setDoc(json);
                docsCache[docUrl] = json;
            }, (fail) => {
                console.log("Failure", fail)
            })
       }
    }, [docUrl]);

    if (Object.keys(doc).length > 0) {
        Object.keys(doc.paths).forEach(path => {
            const endpoint = doc.paths[path];
            Object.keys(endpoint).forEach(method => {
                const rendered = (
                    <Endpoint 
                        httpMethod={method} 
                        path={path} 
                        spec={endpoint[method]} 
                        fullSpec={doc}
                    />
                );
                
                (endpoint[method].tags || []).forEach(tag => {
                    if (!tagToEndpointMap[tag]) {
                        tagToEndpointMap[tag] = [];
                    }
                    tagToEndpointMap[tag].push(rendered);
                });
            });
        });
    }

    const endpoints = Object.keys(tagToEndpointMap).sort().map(tag => {
        return (
            <EndpointGroup label={tag} endpoints={tagToEndpointMap[tag]} hidden={true}/>
        );
    });

    return (
        <div>
            {endpoints}
        </div>
    )

}