import { useState } from "react";
import { Button } from "react-rainbow-components";

export const EndpointGroup = (params) => {

    const {label, endpoints, hidden} = params;

    const [isHidden, setIsHidden] = useState(hidden);

    return (
        <div>
            <h1 style={{marginLeft: "1%", fontSize: "3em"}}>{label}</h1>
            <Button 
                style={{marginLeft: "1%", fontSize: "1em"}} 
                variant="outline-brand" 
                label={`${isHidden ? "Show" : "Hide"} ${endpoints.length} endpoints`} 
                className="rainbow-m-around_medium"
                onClick={() => setIsHidden(!isHidden)} 
            />
            {/* <p style={{marginLeft: "1%", fontSize: "1em"}}></p> */}
            {isHidden ? <div /> : endpoints}
        </div>   
    );
}