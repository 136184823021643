import { useEffect, useState } from "react";

import { Service } from "../components/Service";
import { ServiceGroup } from "../components/ServiceGroup";

export const Demo = () => {

    var host = window.location.host; 
    console.log(host);

    return (
        <div>
            <h1>Tenit Doc Demo UI</h1>
            <ServiceGroup group={
                [
                    {
                        name: "Core Services",
                        docUrl: "https://docs.earnzqa.com/CoreServices/swagger.json"
                    },
                    {
                        name: "Business",
                        docUrl: "https://docs.earnzqa.com/business/swagger.json"
                    },
                    {
                        name: "Promotions",
                        docUrl: "https://docs.earnzqa.com/promotions/swagger.json"
                    },
                    {
                        name: "Transactions",
                        docUrl:"https://docs.earnzqa.com/transactions/swagger.json"
                    }
            ]
            } />
        </div>
    )
}