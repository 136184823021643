import axios from "axios";

export const get = async (url, config, onFinish, onError) => {
  try {
   onFinish((await (axios.get(
        url, config 
        // {
        //   headers: {
        //     ebtc: getBaseToken(),
        //     estc: getSecureToken()
        //   }
        // }
      )
    )).data);
   } catch(e) {
        console.error(e);
        onError();
   }
}

export const put = async (url, body, config, onFinish, onError) => {
    try {
     onFinish((await (axios.put(
          url, body, config 
          // {
          //   headers: {
          //     ebtc: getBaseToken(),
          //     estc: getSecureToken()
          //   }
          // }
        )
      )).data);
     } catch(e) {
          console.error(e);
          onError();
     }
}

export const patch = async (url, body, config, onFinish, onError) => {
    try {
     onFinish((await (axios.patch(
          url, body, config 
          // {
          //   headers: {
          //     ebtc: getBaseToken(),
          //     estc: getSecureToken()
          //   }
          // }
        )
      )).data);
     } catch(e) {
          console.error(e);
          onError();
     }
}

export const post = async (url, body, config, onFinish, onError) => {
    try {
     onFinish((await (axios.post(
          url, body, config 
          // {
          //   headers: {
          //     ebtc: getBaseToken(),
          //     estc: getSecureToken()
          //   }
          // }
        )
      )).data);
     } catch(e) {
          console.error(e);
          onError();
     }
}

export const deleted = async (url, body, config, onFinish, onError) => {
    try {
     onFinish((await (axios.delete(
          url, body, config 
          // {
          //   headers: {
          //     ebtc: getBaseToken(),
          //     estc: getSecureToken()
          //   }
          // }
        )
      )).data);
     } catch(e) {
          console.error(e);
          onError();
     }
}