import React, { useEffect, useState } from 'react';
import { Button, Input, Card, Tabset, Tab, } from 'react-rainbow-components';
import ReactJson from 'react-json-view'
import { get, patch } from '../ApiCaller';
import { getDefaultValueForType, getInputTypeForType } from '../Utils';

const inputStyles = {
    width: 300,
};

export const EndpointTryItOut = (params) => {

    const {httpMethod, path, spec, fullSpec, url} = params;

    const [pathParams, setPathParams] = useState({});
    const [queryParams, setQueryParams] = useState({});

    const [body, setBody] = useState({});
    const [returnedResponse, setReturnedResponse] = useState({});

    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    /*
          "parameters" : [ {
            "in" : "body",
            "name" : "body",
            "required" : false,
            "schema" : {
              "$ref" : "#/definitions/Balance"
            }
          } ],
    */

    useEffect(() => {
        if (spec.responses['200'] && spec.responses['200'].schema && spec.responses['200'].schema.items && Object.keys(spec.responses['200'].schema.items).includes('$ref')) {
            console.log("Response Spec: ", spec.responses['200'])
            const responseModelSpec = fullSpec.definitions[spec.responses['200'].schema.items['$ref'].replaceAll("#/definitions/", "")]
            let responseModel = {};
            Object.keys(responseModelSpec.properties).forEach(key => {
                const parts = responseModelSpec.properties[key];
                console.log("Key %s, value %s", key, getDefaultValueForType(parts.type))
                responseModel[key] = getDefaultValueForType(parts.type);
            });
            setReturnedResponse(responseModel);
        }

        let maybeBody = (spec.parameters || []).filter(p => p.in == 'body');

        // TODO: Is it possible to have more than one body?
        if (maybeBody && maybeBody.length > 0) {
            const bodyParam = maybeBody[0];
            const modelSpec = (fullSpec.definitions[(bodyParam.schema['$ref'] || '').replaceAll("#/definitions/", "")] || {properties: []})
            let bodyModel = {};
            console.log("Model: ", modelSpec)
            Object.keys((modelSpec.properties || {})).forEach(key => {
                const parts = modelSpec.properties[key];
                console.log("Key %s, value %s", key, getDefaultValueForType(parts.type))
                bodyModel[key] = getDefaultValueForType(parts.type);
            });
            setBody(bodyModel);
        }
    }, []);

    const makeApiCall = () => {
        setLoading(true);
        let urlToCall = url;
        Object.keys(pathParams).forEach(pathKey => {
            // console.log("Url before %s update: ", urlToCall)
            urlToCall = urlToCall.replaceAll(`{${pathKey}}`, pathParams[pathKey]);
        });


        let config = {
            params: queryParams,
            headers: {
                ebtc: 'x-service-to-service-customer-id',
                estc: '176'
            }
        };
        switch(httpMethod) {
            case 'get':
                get(urlToCall, config, (result) => {
                    setReturnedResponse(result);
                    console.log("Result", result);
                    setLoading(false);
                    setCollapsed(false);
                }, () => {
                    setLoading(false);
                });
                break;
            case 'patch':
                patch(urlToCall, body, config, (result) => {
                    setReturnedResponse(result);
                    console.log("Result", result);
                    setLoading(false);
                    setCollapsed(false);
                }, () => {
                    setLoading(false);
                });
        }
    }

    const updateValueForInputParam = (paramType, name, value) => {
        console.log("Updating value of %s param '%s' to '%s'", paramType, name, value)
        if (paramType === "path") {
           let params = pathParams;
           params[name] = value;
           setPathParams(params);
           console.log(params);
        } else {
            let params = queryParams;
           params[name] = value;
           setQueryParams(params);
           console.log(params);
        }
    }

    const inputsParameters = (spec.parameters || []).map(i => {

        if (i.in === 'body') {
            // For input bodies, build json input
            //setBody
            return (
                <ReactJson 
                    style={{textAlign: "start", fontSize: "100%"}} 
                    src={body} 
                    theme="solarized"
                    //displayDataTypes={true}
                    //defaultValue={body}
                    enableClipboard={false}
                    onEdit={(edit) => {
                        console.log("Edit", edit);
                        setBody(edit.updated_src);
                    }}
                    onDelete={(deleted) => {
                        console.log("Delete", deleted);
                        //setBody(edit.updated_src);
                    }}
                />
            );
        }

        return (
            <div>
                <Input
                    key={i.name}
                    label={i.name}
                    placeholder={getDefaultValueForType(i.type)}
                    type={getInputTypeForType(i.type)}
                    className="rainbow-p-around_medium"
                    //value={(i.in === "path" ? pathParams[i.name] : queryParams[i.name]) || ""}
                    onChange={e => updateValueForInputParam(i.in, i.name, e.target.value)}
                    style={inputStyles}
                />
            </div>
        )
    });

    // return <p>Trying it out</p>;
    
    return (
            <Card
                isLoading={loading}
                //icon={<FontAwesomeIcon icon={faUsers} size="lg" className="rainbow-color_brand" />}
                title={`${httpMethod.toUpperCase()} ${path}`}
                style={{borderColor: "white", margin: "1%"}}
            //     actions={
            //         <ButtonGroup>
            //             <ButtonIcon variant="border" icon={<FontAwesomeIcon icon={faPlus} />} />
            //             <ButtonIcon variant="border" icon={<FontAwesomeIcon icon={faEdit} />} disabled />
            //             <ButtonIcon
            //                 variant="border"
            //                 icon={<FontAwesomeIcon icon={faAngleDown} />}
            //                 disabled
            //             />
            //         </ButtonGroup>
            // }
            >
            {inputsParameters}
            <div style={{display: 'flex'}}>
                <Button style={{marginLeft: "1%"}} onClick={makeApiCall}>Send Request</Button>
            </div>
            <h3>Response:</h3>
            {/* {response} */}
            { Object.keys(returnedResponse).length > 0 ?
            <ReactJson 
                style={{textAlign: "start", fontSize: "100%"}} 
                src={returnedResponse} 
                theme="solarized"
                displayDataTypes={false}
                collapsed={collapsed}
            />
            : <div />}
            </Card>
    );

}
