export function getDefaultValueForType(type) {
    switch(type) {
        case 'integer':
        case 'number': 
            return 123;
        case 'boolean': 
            return true;
    }
    return 'string'; // Default to string if unknown
}

export function getInputTypeForType(type) {
    console.log("Have input of type: ", type)
    switch(type) {
        case 'integer': 
        case 'number':
            return 'number';
        case 'boolean': 
            return "text";
    }
    return 'text'; // Default to string if unknown
}

export function indent(n) {
    let s = '';
    for (let i = 0; i < n; i++) {
        s +='\t';
    }
    return s;
}