import React, { useEffect, useState } from 'react';
import { Button, Input, Card, Tabset, MarkdownOutput } from 'react-rainbow-components';
import { get } from '../ApiCaller';
import { JavascriptSyntaxHighlights } from '../Styles';
import { getDefaultValueForType, getInputTypeForType, indent } from '../Utils';

const inputStyles = {
    width: 300,
};

export const EndpointAxiosJavascript = (params) => {

    const {httpMethod, path, spec, url} = params;

    const [pathParams, setPathParams] = useState({});
    const [queryParams, setQueryParams] = useState({});

    const [value, setValue] = useState('```js\nconsole.log("hello world");\n```"');

    useEffect(() => {
        buildAxiosApiCall();
    }, []);

    const buildAxiosApiCall = () => {
        setValue(
            '```js' + 
            `
import axios from "axios";

export const ${spec.operationId} = async (${Object.keys(queryParams).length > 0 ? Object.keys(queryParams) + ', ' : ''}onSuccess, onError) => {
    try {
            onSuccess((await (axios.get("${url}"))).data);
        } catch(e) {
            console.error(e);
            onError();
        }
} 
            `
        );
    }

    // return <p>Trying it out</p>;
    
    return (
            <Card
                title={`${httpMethod.toUpperCase()} ${path}`}
                style={{borderColor: "white", margin: "1%"}}
            >
                <JavascriptSyntaxHighlights>
                    <MarkdownOutput value={value} style={{margin: "2%"}} />
                </JavascriptSyntaxHighlights>
            </Card>
    );

}
