import { useEffect, useState } from "react";
import { VerticalNavigation, VerticalSection, VerticalItem } from 'react-rainbow-components';
import { Service } from "./Service";


export const ServiceGroup = (params) => {

    const [group, setGroup] = useState(params.group || []);

    const [selectedService, setSelectedService] = useState();

    useEffect(() => {
        setSelectedService((group[0] || {name: ""}).name);
    }, []);


    const NavItems = group.map(s => <VerticalItem name={s.name} label={s.name} />);

    const selectedDoc = group.filter(s => s.name === selectedService);
    console.log("Selected Doc: ", selectedDoc)
    if (selectedDoc.length === 0) {
        return <div></div>;
    }
    const selectedDocUrl = selectedDoc[0].docUrl;
    console.log("Selected Doc url: ", selectedDocUrl);

    return (
        <div style={{display: "flex", height: "100vh"}}>
            <VerticalNavigation
                selectedItem={selectedService}
                onSelect={(event, selectedItem) => {
                    setSelectedService(selectedItem);
                }}
                style={{width: "20%", marginRight: "3%"}}
            >
                <VerticalSection>
                    { NavItems }
                </VerticalSection>
            </VerticalNavigation>
            <Service docUrl={selectedDocUrl}/>
        </div>
    );
}